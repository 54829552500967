<template>
    <div class="tgcss-max-w-[1200px] tgcss-m-auto tgcss-w-full tgcss-relative">


        <div class="card tgcss-w-full tgcss-mb-5">
            <div class="card-body tgcss-w-full">
                <div class="tgcss-grid tgcss-p-6 tgcss-grid-cols-2 tgcss-gap-10">
                    <div>
                        
                        <div class="tgcss-flex tgcss-w-full tgcss-overflow-x-auto tgcss-pb-3 tgcss-pt-1 tgcss-mb-5">
                            <div @click="type = 'toman'" :class="'market-main-sub-tab-item ' + (type == 'toman' ? '!tgcss-bg-gray-900 !tgcss-text-white' : '') " class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-sm tgcss-cursor-pointer">
                                واریز تومان
                            </div>
                            <div @click="type = 'coin'" :class="'market-main-sub-tab-item ' + (type == 'coin' ? '!tgcss-bg-gray-900 !tgcss-text-white' : '') " class="tgcss-bg-gray-100 hover:tgcss-bg-gray-900 hover:tgcss-text-white tgcss-whitespace-nowrap tgcss-text-gray-900 tgcss-rounded-full tgcss-ml-2 tgcss-px-2.5 tgcss-pb-1.5 tgcss-pt-1 tgcss-font-semibold tgcss-text-sm tgcss-cursor-pointer">
                                واریز کوین
                            </div>
                        </div>


                        <slot v-if="type == 'coin'">
                            <div class="tgcss-bg-[#ebf3ff] tgcss-rounded-md tgcss-p-5 tgcss-flex tgcss-items-start tgcss-mb-3">
                                <svg class="tgcss-w-full tgcss-max-w-7 tgcss-text-[#2175f5]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path clip-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill-rule="evenodd"></path>
                                </svg>
                                <div class="tgcss-flex-grow tgcss-mr-4">
                                    <div class="tgcss-leading-5">برای امنیت بیشتر و حفظ حریم خصوصی شما در شبکه بلاک‌چین، آدرس‌های کیف‌پول به صورت دوره‌ای تغییر خواهد یافت.</div>
                                </div>
                            </div>
                            
                            <div class="tgcss-bg-[#fff2e0] tgcss-rounded-md tgcss-p-5 tgcss-flex tgcss-items-center tgcss-mb-3">
                                <svg class="tgcss-w-full tgcss-max-w-7 tgcss-text-[#ff9e15]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path clip-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill-rule="evenodd"></path>
                                </svg>
                                <div class="tgcss-flex-grow tgcss-mr-4">
                                    <div class="tgcss-leading-5">از واریز قرارداد هوشمند خودداری کنید.</div>
                                </div>
                            </div>

                            
                            <div class="tgcss-bg-[#fff2e0] tgcss-rounded-md tgcss-p-5 tgcss-flex tgcss-items-start tgcss-mb-6">
                                <svg class="tgcss-w-full tgcss-max-w-7 tgcss-text-[#ff9e15]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path clip-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill-rule="evenodd"></path>
                                </svg>
                                <div class="tgcss-flex-grow tgcss-mr-4">
                                    <div class="tgcss-leading-5">از واریز مستقیم از صرافی‌های بین‌المللی، به‌خصوص صرافی‌های آمریکایی مانند CoinBase و Kraken که کاربران ایرانی را محدود می‌کنند، خودداری کنید. در صورت نیاز از کیف‌پول شخصی، آدرس‌های یک‌بار مصرف و روش‌های انتقال چند لایه برای حفظ امنیت دارایی‌هایتان استفاده کنید.</div>
                                </div>
                            </div>


                            <div class="tgcss-mb-6">
                                <div class="tgcss-flex tgcss-mb-2">
                                    <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow">
                                        کوین
                                    </div>
                                </div>
                                <div class="tgcss-flex tgcss-items-center tgcss-w-full tgcss-rounded-md tgcss-border tgcss-border-gray-200 tgcss-relative" >
                                    <div class="tgcss-cursor-pointer tgcss-h-12 tgcss-flex tgcss-items-center tgcss-w-full" @click="CoinsModal = true">
                                        <img class="tgcss-w-6 tgcss-mr-3 tgcss-ml-1" :src="'https://api.wallex.ir/coins/'+coins[coinSelect].symbol+'/icon/svg'">
                                        <div class="tgcss-flex-grow tgcss-text-right tgcss-font-semibold tgcss-text-base">
                                            {{coins[coinSelect].nameFa}} ({{coins[coinSelect].symbol}})
                                        </div>
                                        <svg class="tgcss-w-6 tgcss-ml-2 tgcss-relative tgcss-top-[1px]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path clip-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" fill-rule="evenodd"></path>
                                        </svg>           
                                    </div>
                                </div>
                            </div>

                            <div v-if="coins[coinSelect].networks" class="">
                                <div class="tgcss-flex tgcss-mb-2">
                                    <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow">
                                        شبکه
                                    </div>
                                </div>
                                <div class="tgcss-flex tgcss-items-center tgcss-w-full tgcss-rounded-md tgcss-border tgcss-border-gray-200 tgcss-relative">
                                    <div class="tgcss-cursor-pointer tgcss-h-12 tgcss-flex tgcss-items-center tgcss-w-full" @click="showNetworks = true">
                                        <div class="tgcss-flex-grow tgcss-text-right tgcss-pr-6 tgcss-font-semibold tgcss-text-base">
                                            <slot v-if="networkSelect == ''">
                                                - انتخاب شبکه
                                            </slot>
                                            <slot v-else>
                                                {{networkSelect}}
                                            </slot>
                                            
                                        </div>
                                        <svg class="tgcss-w-6 tgcss-ml-2 tgcss-relative tgcss-top-[1px]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path clip-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" fill-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <div v-if="showNetworks"  @click="showNetworks = false" class="tgcss-inset-0 tgcss-fixed tgcss-bg-gray-700 tgcss-opacity-20 tgcss-z-10"></div>
                                    <div v-if="showNetworks" class="tgcss-absolute tgcss-w-full tgcss-bg-white tgcss-top-12 tgcss-rounded-md tgcss-z-50 tgcss-p-3 tgcss-pb-1">
                                        
                                        <div v-for="(item, index) in coins[coinSelect].networks" v-bind:key="index" @click="networkSelect = item;showNetworks = false" class="tgcss-cursor-pointer hover:tgcss-bg-gray-100 tgcss-mb-1 tgcss-pt-1.5 tgcss-pb-2.5 tgcss-rounded-md tgcss-flex tgcss-items-center">
                                            <div class="tgcss-flex-grow tgcss-pl-6 tgcss-text-right tgcss-pr-3 tgcss-font-semibold tgcss-text-sm tgcss-pt-0.5" dir="ltr">
                                                {{item}}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                        </slot>


                        <slot v-if="type == 'toman'">

                            <div class="tgcss-mb-6">
                                <div class="tgcss-flex tgcss-mb-2">
                                    <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow">
                                        شماره کارت
                                    </div>
                                    <router-link :to="{ name: 'ExchangeWalletBankAccounts' }" :data-target="'ExchangeWalletBankAccounts'"  class="tgcss-flex tgcss-font-semibold tgcss-text-sm !tgcss-text-[#f8931d] tgcss-cursor-pointer !tgcss-no-underline">
                                        <svg class="tgcss-w-5 tgcss-ml-[3px] tgcss-relative tgcss-top-[1px]"  data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path clip-rule="evenodd" d="M10 18a8 8 0 1 0 0-16 8 8 0 0 0 0 16Zm.75-11.25a.75.75 0 0 0-1.5 0v2.5h-2.5a.75.75 0 0 0 0 1.5h2.5v2.5a.75.75 0 0 0 1.5 0v-2.5h2.5a.75.75 0 0 0 0-1.5h-2.5v-2.5Z" fill-rule="evenodd"></path>
                                        </svg>
                                        افزودن 
                                    </router-link>
                                </div>
                                <div class="tgcss-flex tgcss-items-center tgcss-w-full tgcss-rounded-md tgcss-border tgcss-border-gray-200 tgcss-relative">
                                    <div class="tgcss-cursor-pointer tgcss-h-12 tgcss-flex tgcss-items-center tgcss-w-full" @click="showCards = true">
                                        <img class="tgcss-w-6 tgcss-mr-3 tgcss-ml-1" :src="bankCards[cardSelect].logo">
                                        <div class="tgcss-flex-grow tgcss-text-center tgcss-font-semibold tgcss-text-base" dir="ltr">
                                            {{bankCards[cardSelect].CardNumber}}
                                        </div>
                                        <svg class="tgcss-w-6 tgcss-ml-2 tgcss-relative tgcss-top-[1px]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path clip-rule="evenodd" d="M5.22 8.22a.75.75 0 0 1 1.06 0L10 11.94l3.72-3.72a.75.75 0 1 1 1.06 1.06l-4.25 4.25a.75.75 0 0 1-1.06 0L5.22 9.28a.75.75 0 0 1 0-1.06Z" fill-rule="evenodd"></path>
                                        </svg>
                                    </div>
                                    <div v-if="showCards"  @click="showCards = false" class="tgcss-inset-0 tgcss-fixed tgcss-bg-gray-700 tgcss-opacity-20 tgcss-z-10"></div>
                                    <div v-if="showCards" class="tgcss-absolute tgcss-w-full tgcss-bg-white tgcss-top-12 tgcss-rounded-md tgcss-z-50 tgcss-p-3 tgcss-pb-1">
                                        
                                        <div v-for="(item, index) in bankCards" v-bind:key="index" @click="cardSelect = index;showCards = false" class="tgcss-cursor-pointer tgcss-bg-gray-100 tgcss-mb-1 tgcss-pt-1.5 tgcss-pb-2.5 tgcss-rounded-md tgcss-flex tgcss-items-center">
                                            <img class="tgcss-w-6 tgcss-mr-3 tgcss-ml-1" :src="item.logo">
                                            <div class="tgcss-flex-grow tgcss-pl-6 tgcss-text-center tgcss-font-semibold tgcss-text-sm tgcss-pt-0.5" dir="ltr">
                                                {{item.CardNumber}}
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>

                            <div class="tgcss-mb-6">
                                <div class="tgcss-flex tgcss-mb-2">
                                    <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow">
                                        مقدار واریز
                                    </div>
                                </div>
                                <div class="tgcss-flex tgcss-items-center tgcss-w-full tgcss-rounded-md tgcss-border tgcss-border-gray-200 tgcss-relative">
                                    <div class="tgcss-h-12 tgcss-flex tgcss-items-center tgcss-w-full tgcss-relative">
                                        <input type="text" class="tgcss-absolute tgcss-w-full !tgcss-shadow-none !tgcss-text-base !tgcss-text-gray-700 tgcss-font-semibold !tgcss-bg-transparent !tgcss-h-12 !tgcss-px-3 !tgcss-border-none tgcss-z-20">
                                        <div class="tgcss-ml-3 tgcss-text-base tgcss-font-semibold tgcss-opacity-60 tgcss-text-left tgcss-w-full">
                                            تومان
                                        </div>
                                    </div>
                                </div>
                                <div class="tgcss-flex tgcss-mb-2 tgcss-mt-1 tgcss-text-red-500">
                                    <div class="tgcss-flex tgcss-font-semibold tgcss-text-xs tgcss-flex-grow">
                                        <svg class="tgcss-w-4 tgcss-ml-[3px] tgcss-relative tgcss-top-[1px]"  data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                            <path clip-rule="evenodd" d="M18 10a8 8 0 1 1-16 0 8 8 0 0 1 16 0Zm-8-5a.75.75 0 0 1 .75.75v4.5a.75.75 0 0 1-1.5 0v-4.5A.75.75 0 0 1 10 5Zm0 10a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill-rule="evenodd"></path>
                                        </svg>
                                        حداقل ۱۰ هزار تومان
                                    </div>
                                </div>
                            </div>


                            <div class="tgcss-pt-6">
                                <div class="tgcss-flex tgcss-mb-2">
                                    <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                        کارمزد شاپرک
                                    </div>
                                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-text-base tgcss-cursor-pointer">
                                        120
                                        <div class="tgcss-text-xs tgcss-opacity-60 tgcss-mr-1">تومان</div>
                                    </div>
                                </div>
                                <div class="tgcss-flex tgcss-mb-2">
                                    <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                        مبلغ واریزی به کیف‌پول
                                    </div>
                                    <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-text-base tgcss-cursor-pointer">
                                        9,880
                                        <div class="tgcss-text-xs tgcss-opacity-60 tgcss-mr-1">تومان</div>
                                    </div>
                                </div>
                            </div>

                            <div class="tgcss-w-full tgcss-flex tgcss-font-semibold tgcss-text-base tgcss-mt-5">
                                <button class="tgcss-bg-[#f8931d] tgcss-w-full tgcss-text-white tgcss-font-bold tgcss-pt-2 tgcss-pb-3 tgcss-px-4 !tgcss-rounded-md">
                                    پرداخت از طریق درگاه بانکی
                                </button>
                            </div>
                        </slot>

                    </div>


                    <div>
                        <slot v-if="type == 'toman'">
                            <div class="tgcss-bg-[#fff2e0] tgcss-rounded-md tgcss-p-5 tgcss-flex tgcss-items-start">
                                <svg class="tgcss-w-full tgcss-max-w-7 tgcss-text-[#ff9e15]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                    <path clip-rule="evenodd" d="M8.485 2.495c.673-1.167 2.357-1.167 3.03 0l6.28 10.875c.673 1.167-.17 2.625-1.516 2.625H3.72c-1.347 0-2.189-1.458-1.515-2.625L8.485 2.495ZM10 5a.75.75 0 0 1 .75.75v3.5a.75.75 0 0 1-1.5 0v-3.5A.75.75 0 0 1 10 5Zm0 9a1 1 0 1 0 0-2 1 1 0 0 0 0 2Z" fill-rule="evenodd"></path>
                                </svg>
                                <div class="tgcss-flex-grow tgcss-mr-4">
                                    <div class="tgcss-leading-5">
                                        <div class="tgcss-font-semibold tgcss-mb-1">لطفاً در صورت استفاده از فیلترشکن، آن را خاموش کنید.</div>
                                        از واریز هر گونه وجه به حساب افراد ناشناس که از طریق آگهی‌های درآمدزایی و ... شما را پیدا کرده‌اند خودداری کنید. این شیوه کلاه‌برداری بوده و مسئولیت جرم صورت‌گرفته متوجه شما خواهد بود.
                                    </div>
                                </div>
                            </div>
                        </slot>

                        <slot v-if="type == 'coin'">                          
                            <div v-if="networkSelect == ''" class="tgcss-flex tgcss-items-center tgcss-justify-center tgcss-h-full tgcss-w-full">
                                <div class="tgcss-text-center">
                                    <svg class="tgcss-w-36 tgcss-m-auto tgcss-opacity-50 tgcss-text-[#f8931d]" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                                        <path clip-rule="evenodd" d="M9.638 1.093a.75.75 0 0 1 .724 0l2 1.104a.75.75 0 1 1-.724 1.313L10 2.607l-1.638.903a.75.75 0 1 1-.724-1.313l2-1.104ZM5.403 4.287a.75.75 0 0 1-.295 1.019l-.805.444.805.444a.75.75 0 0 1-.724 1.314L3.5 7.02v.73a.75.75 0 0 1-1.5 0v-2a.75.75 0 0 1 .388-.657l1.996-1.1a.75.75 0 0 1 1.019.294Zm9.194 0a.75.75 0 0 1 1.02-.295l1.995 1.101A.75.75 0 0 1 18 5.75v2a.75.75 0 0 1-1.5 0v-.73l-.884.488a.75.75 0 1 1-.724-1.314l.806-.444-.806-.444a.75.75 0 0 1-.295-1.02ZM7.343 8.284a.75.75 0 0 1 1.02-.294L10 8.893l1.638-.903a.75.75 0 1 1 .724 1.313l-1.612.89v1.557a.75.75 0 0 1-1.5 0v-1.557l-1.612-.89a.75.75 0 0 1-.295-1.019ZM2.75 11.5a.75.75 0 0 1 .75.75v1.557l1.608.887a.75.75 0 0 1-.724 1.314l-1.996-1.101A.75.75 0 0 1 2 14.25v-2a.75.75 0 0 1 .75-.75Zm14.5 0a.75.75 0 0 1 .75.75v2a.75.75 0 0 1-.388.657l-1.996 1.1a.75.75 0 1 1-.724-1.313l1.608-.887V12.25a.75.75 0 0 1 .75-.75Zm-7.25 4a.75.75 0 0 1 .75.75v.73l.888-.49a.75.75 0 0 1 .724 1.313l-2 1.104a.75.75 0 0 1-.724 0l-2-1.104a.75.75 0 1 1 .724-1.313l.888.49v-.73a.75.75 0 0 1 .75-.75Z" fill-rule="evenodd"></path>
                                    </svg>
                                    <div class="tgcss-pt-5">- ابتدا شبکه را انتخاب کنید - </div>
                                </div>
                            </div>                          
                            <div v-else class="tgcss-flex tgcss-items-center tgcss-justify-center tgcss-h-full tgcss-w-full">
                                <div class="tgcss-text-center">
                                    <img class="tgcss-w-full tgcss-max-w-40 tgcss-m-auto" src="https://biaupload.com/do.php?imgf=org-54ffa500d40b1.png">
                                    <span class="tgcss-text-lg tgcss-py-4 tgcss-block tgcss-font-semibold">آدرس برای واریز در شبکه  {{ networkSelect }}</span>

                                    <div class="tgcss-bg-[#ebf3ff] tgcss-rounded-md tgcss-p-5 tgcss-flex tgcss-items-center tgcss-mb-3 tgcss-break-words" dir="ltr">
                                        <svg class="tgcss-w-8 tgcss-mr-3 tgcss-text-[#2175f5]" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                                            <path d="M15.75 17.25v3.375c0 .621-.504 1.125-1.125 1.125h-9.75a1.125 1.125 0 0 1-1.125-1.125V7.875c0-.621.504-1.125 1.125-1.125H6.75a9.06 9.06 0 0 1 1.5.124m7.5 10.376h3.375c.621 0 1.125-.504 1.125-1.125V11.25c0-4.46-3.243-8.161-7.5-8.876a9.06 9.06 0 0 0-1.5-.124H9.375c-.621 0-1.125.504-1.125 1.125v3.5m7.5 10.375H9.375a1.125 1.125 0 0 1-1.125-1.125v-9.25m12 6.625v-1.875a3.375 3.375 0 0 0-3.375-3.375h-1.5a1.125 1.125 0 0 1-1.125-1.125v-1.5a3.375 3.375 0 0 0-3.375-3.375H9.75" stroke-linecap="round" stroke-linejoin="round"></path>
                                        </svg>
                                        <div class="tgcss-flex-grow tgcss-mr-4 tgcss-text-sm tgcss-text-left tgcss-font-semibold tgcss-break-words tgcss-max-w-80">
                                            1MmNiLU23ZZW7Y9Bf8UAPW2PE8PMBX8wut1MmNiL
                                        </div>
                                    </div>

                                    <div class="tgcss-text-xs tgcss-font-medium tgcss-block tgcss-text-right tgcss-mt-5">
                                        حداقل میزان واریز 0.0002 {{ networkSelect }}  است.
                                    </div>
                                    <div class="tgcss-text-xs tgcss-font-medium tgcss-block tgcss-text-right tgcss-mt-5">
                                        مبلغ واریزی بعد از 2 تأیید از سمت شبکه، واریز خواهد شد.
                                    </div>
                                    <div class="tgcss-text-xs tgcss-font-medium tgcss-block tgcss-text-right tgcss-mt-5">
                                        ارسال کوین غیر از {{ networkSelect }} به این آدرس ممکن است منجر به از دست دادن آن برای همیشه شود.
                                    </div>

                                </div>

                            </div>
                        </slot>
                    </div>
                </div>
            </div>
        </div>

        <div v-if="CoinsModal" class="CoinsModal tgcss-fixed tgcss-inset-0 tgcss-bg-black/20 tgcss-z-50 tgcss-flex tgcss-justify-center tgcss-items-center tgcss-content-center">
            <div class="tgcss-w-96 tgcss-bg-white tgcss-h-1/2 tgcss-rounded-md">
                <div class="tgcss-p-5">
                    <div class="tgcss-w-full tgcss-flex tgcss-items-center">
                        <div class="tgcss-flex-grow tgcss-text-base tgcss-font-semibold">انتخاب کوین</div>
                        <svg @click="CoinsModal = false" class="tgcss-w-7 tgcss-p-1 tgcss-bg-gray-100 tgcss-cursor-pointer tgcss-text-gray-700 tgcss-rounded-md" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"></path>
                        </svg>
                    </div>
                    <label class=" tgcss-items-center tgcss-bg-gray-100 tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-cursor-pointer !tgcss-hidden md:!tgcss-flex tgcss-my-3">
                        <svg class="tgcss-w-6 tgcss-mr-1" data-slot="icon" aria-hidden="true" fill="none" stroke-width="1.5" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                            <path d="m21 21-5.197-5.197m0 0A7.5 7.5 0 1 0 5.196 5.196a7.5 7.5 0 0 0 10.607 10.607Z" stroke-linecap="round" stroke-linejoin="round"></path>
                        </svg>
                        <input type="text" v-model="searchCoins" placeholder="جستجوی کوین ..." class="!tgcss-bg-transparent !tgcss-border-none !tgcss-shadow-none ">
                    </label>
                </div>

                <div class="tgcss-divide-y tgcss-overflow-auto tgcss-h-[calc(100%-120px)]">
                    <div v-for="(item, index) in filteredCoins" v-bind:key="index" @click="coinSelect = index;CoinsModal = false;networkSelect = ''" class="tgcss-cursor-pointer hover:tgcss-bg-gray-100 tgcss-py-2  tgcss-flex tgcss-items-center">
                        <img class="tgcss-w-7 tgcss-mr-5 tgcss-ml-2" :src="'https://api.wallex.ir/coins/'+item.symbol+'/icon/svg'">
                        <div class="tgcss-flex-grow tgcss-pl-6 tgcss-text-right tgcss-font-semibold tgcss-text-sm tgcss-pt-0.5">
                            {{item.nameFa}} ({{item.symbol}})
                        </div>
                    </div>
                </div>


                
            </div>

        </div>


        <div v-if="modalDetails" class="tgcss-fixed tgcss-inset-0 tgcss-bg-black/20 tgcss-z-50 tgcss-flex tgcss-justify-center tgcss-items-center tgcss-content-center">
            <div class="tgcss-w-96 tgcss-bg-white tgcss-rounded-md">
                <div class="tgcss-p-5">
                    <div class="tgcss-w-full tgcss-flex tgcss-items-center">
                        <div class="tgcss-flex-grow tgcss-text-base tgcss-font-semibold">جزئیات</div>
                        <svg @click="modalDetails = false" class="tgcss-w-7 tgcss-p-1 tgcss-bg-gray-100 tgcss-cursor-pointer tgcss-text-gray-700 tgcss-rounded-md" data-slot="icon" aria-hidden="true" fill="currentColor" viewBox="0 0 20 20" xmlns="http://www.w3.org/2000/svg">
                            <path d="M6.28 5.22a.75.75 0 0 0-1.06 1.06L8.94 10l-3.72 3.72a.75.75 0 1 0 1.06 1.06L10 11.06l3.72 3.72a.75.75 0 1 0 1.06-1.06L11.06 10l3.72-3.72a.75.75 0 0 0-1.06-1.06L10 8.94 6.28 5.22Z"></path>
                        </svg>
                    </div>
                </div>
                <svg class="tgcss-bg-green-200 tgcss-rounded-full tgcss-p-3 tgcss-text-green-800 tgcss-w-14 tgcss-m-auto" data-slot="icon" aria-hidden="true" fill="none" stroke-width="2" stroke="currentColor" viewBox="0 0 24 24" xmlns="http://www.w3.org/2000/svg">
                    <path d="M19.5 13.5 12 21m0 0-7.5-7.5M12 21V3" stroke-linecap="round" stroke-linejoin="round"></path>
                </svg>
                <div class="tgcss-font-semibold tgcss-text-2xl tgcss-block tgcss-w-full tgcss-pt-3 tgcss-text-center">واریز تتر USDT</div>
                <div class="tgcss-p-5 tgcss-pt-0">
                    <div class="tgcss-pt-6 tgcss-divide-dashed tgcss-divide-y tgcss-divide-gray-300">
                        <div class="tgcss-flex tgcss-items-center tgcss-py-3 tgcss-w-full">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                تاریخ
                            </div>
                            <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-text-sm tgcss-cursor-pointer">
                                14:21 1402/04/31
                            </div>
                        </div>
                        <div class="tgcss-flex tgcss-py-3 tgcss-w-full">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                نام کوین
                            </div>
                            <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-text-sm tgcss-cursor-pointer">
                                تتر (USDT) 
                            </div>
                        </div>
                        <div class="tgcss-flex tgcss-py-3 tgcss-w-full">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                مقدار
                            </div>
                            <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-text-sm tgcss-cursor-pointer">
                                372.63
                            </div>
                        </div>
                        <div class="tgcss-flex tgcss-py-3 tgcss-w-full">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                نوع واریز
                            </div>
                            <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-text-sm tgcss-cursor-pointer">
                                عادی
                            </div>
                        </div>
                        <div class="tgcss-flex tgcss-py-3 tgcss-w-full">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                شبکه
                            </div>
                            <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-text-sm tgcss-cursor-pointer">
                                TRC20
                            </div>
                        </div>
                        <div class="tgcss-flex tgcss-py-3 tgcss-w-full">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                TXID
                            </div>
                            <div class="tgcss-flex tgcss-items-center tgcss-font-semibold tgcss-text-sm tgcss-cursor-pointer">
                                <a href="" class="tgcss-max-w-44 tgcss-break-words tgcss-text-left">7715255d63ffcb79868f1cff6026654998ce76b86f49667c01e38ce545e4f4b3</a>
                            </div>
                        </div>
                        <div class="tgcss-flex tgcss-py-3 tgcss-w-full">
                            <div class="tgcss-font-semibold tgcss-text-sm tgcss-flex-grow tgcss-opacity-80">
                                وضعیت
                            </div>
                            <div dir="rtl" class="tgcss-w-auto tgcss-text-xs tgcss-font-semibold tgcss-inline-block tgcss-bg-green-200 tgcss-rounded-full tgcss-px-3 tgcss-pb-1.5 tgcss-pt-1 tgcss-text-green-800"> موفق </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <slot v-if="type == 'toman'">
            <div class="card">
            <div class="card-body !tgcss-pb-0">
                    <div class="tgcss-flex tgcss-items-center tgcss-mb-4">
                        <div class="tgcss-flex-grow">
                            <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">آخرین واریزهای تومان</div>
                        </div>

                        
                        <div class="tgcss-items-center tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-text-sm tgcss-mr-3 tgcss-cursor-pointer !tgcss-hidden md:!tgcss-flex">
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">نمایش همه</div>
                        </div>
                    </div>

                    <div class="-tgcss-mx-5 tgcss-overflow-x-auto">
                        <table class="tgcss-w-full tgcss-whitespace-nowrap !tgcss-border-none !tgcss-bg-transparent">
                            <thead class="ltr:tgcss-text-left rtl:tgcss-text-right">
                                <tr>
                                    <th class="tgcss-px-4 tgcss-py-3 tgcss-pr-6 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">زمان</th>
                                    <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">مقدار واریزی (تومان)</th>
                                    <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden sm:tgcss-table-cell">شماره‌کارت</th>
                                    <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">کد‌رهگیری</th>
                                    <th class="tgcss-px-4 tgcss-py-3 tgcss-pl-[20px]  tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 text-center tgcss-hidden md:tgcss-table-cell">وضعیت</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredItems" v-bind:key="index" @click="modalDetails=true" class="hover:tgcss-bg-slate-100 tgcss-group tgcss-cursor-pointer">
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700">
                                        <div class="">21:34 1402/06/28</div>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden sm:tgcss-table-cell">
                                        <div class="">{{item.changeTotalFa}}</div>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden md:tgcss-table-cell tgcss-text-right" dir="ltr">
                                        <div class="">6219 8610 2565 1323</div>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-bl tgcss-hidden sm:tgcss-table-cell">
                                        0q1xmrr
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 tgcss-text-sm tgcss-text-gray-700 tgcss-font-semibold text-left tgcss-hidden md:tgcss-table-cell">
                                        <div v-if="item.actions.withdraw" class="tgcss-bg-emerald-100 tgcss-text-emerald-600 tgcss-font-bold tgcss-pt-2 tgcss-pb-3 tgcss-text-center tgcss-w-full tgcss-px-4 !tgcss-rounded-md"> موفق </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </slot>
        <slot v-if="type == 'coin'">
            <div class="card">
            <div class="card-body !tgcss-pb-0">
                    <div class="tgcss-flex tgcss-items-center tgcss-mb-4">
                        <div class="tgcss-flex-grow">
                            <div class="tgcss-font-semibold tgcss-text-lg tgcss-flex-grow">آخرین واریزهای کوین</div>
                        </div>

                        
                        <div class="tgcss-items-center tgcss-rounded-md tgcss-p-1 tgcss-px-1 tgcss-font-semibold tgcss-text-sm tgcss-mr-3 tgcss-cursor-pointer !tgcss-hidden md:!tgcss-flex">
                            <div class="tgcss-rounded-md tgcss-p-0.5 tgcss-px-2">نمایش همه</div>
                        </div>
                    </div>
                    

                    <div class="-tgcss-mx-5 tgcss-overflow-x-auto">
                        <table class="tgcss-w-full tgcss-whitespace-nowrap !tgcss-border-none !tgcss-bg-transparent">
                            <thead class="ltr:tgcss-text-left rtl:tgcss-text-right">
                                <tr>
                                    <th class="tgcss-px-4 tgcss-py-3 tgcss-pr-6 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">زمان</th>
                                    <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 ">نام کوین</th>
                                    <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden sm:tgcss-table-cell">مقدار</th>
                                    <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">نوع واریز</th>
                                    <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">شبکه</th>
                                    <th class="tgcss-px-4 tgcss-py-3 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 tgcss-hidden md:tgcss-table-cell">TXID</th>
                                    <th class="tgcss-px-4 tgcss-py-3 tgcss-pl-[20px]  tgcss-font-semibold tgcss-border-b tgcss-border-slate-200 text-center tgcss-hidden md:tgcss-table-cell">وضعیت</th>
                                </tr>
                            </thead>
                            <tbody>
                                <tr v-for="(item, index) in filteredItems" v-bind:key="index" @click="modalDetails=true" class="hover:tgcss-bg-slate-100 tgcss-group">
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700">
                                        <div class="">21:34 1402/06/28</div>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-3 tgcss-pr-[18px] tgcss-border-y tgcss-border-gray-200 ">
                                        <div class="tgcss-flex tgcss-gap-2 tgcss-items-center">
                                            <a href="#" class="grow !tgcss-no-underline">
                                                <h3 class="!tgcss-font-bold !tgcss-m-0 !tgcss-text-sm tgcss-flex tgcss-items-center !tgcss-text-gray-700">{{item.nameFa}} <span class="tgcss-hidden md:tgcss-inline-block tgcss-opacity-50 tgcss-relative top-0.5 tgcss-mr-1">{{item.symbol}}</span></h3>
                                            </a>
                                        </div>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden sm:tgcss-table-cell">
                                        <div class="">{{item.deposit}}</div>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden md:tgcss-table-cell">
                                        <div class="">عادی</div>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-gray-700 tgcss-hidden md:tgcss-table-cell">
                                        <div class="">TRC20</div>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 first:tgcss-pl-5 last:tgcss-pr-5 tgcss-text-sm tgcss-font-semibold tgcss-text-bl tgcss-hidden sm:tgcss-table-cell tgcss-text-blue-500">
                                        <a href="" class="">00d5dfa3c37eda...6a1c31b39e008</a>
                                    </td>
                                    <td class="tgcss-px-3.5 tgcss-py-2.5 tgcss-text-sm tgcss-text-gray-700 tgcss-font-semibold text-left tgcss-hidden md:tgcss-table-cell">
                                        <div v-if="item.actions.withdraw" class="tgcss-bg-emerald-100 tgcss-text-emerald-600 tgcss-font-bold tgcss-pt-2 tgcss-pb-3 tgcss-text-center tgcss-w-full tgcss-px-4 !tgcss-rounded-md"> موفق </div>
                                    </td>
                                </tr>
                            </tbody>
                        </table>
                    </div>
                </div>
            </div>
        </slot>

    </div>
</template>


<script>

export default {
    name: 'CryptoWalletDeposit',
    components: {},
    data: function () {
        return {
            type:"toman",
            modalDetails:false,
            CoinsModal:false,
            showCards:false,
            showNetworks:false,
            coinSelect:0,
            networkSelect:"",
            searchCoins:'',
            search:'',
            cardSelect:1,
            List:[
                {
                    "id":"1",
                    "name":"Bitcoin",
                    "nameFa":"ریال",
                    "symbol":"Rial",
                    "pType":"Rial",
                    "pTypeFa":"ریال",
                    "deposit": "450,000",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"2,253,366",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"32,500",
                    "changeTotalFa":"450,000",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":false,
                    }
                },
                {
                    "id":"1",
                    "name":"Bitcoin",
                    "nameFa":"بیت‌کوین",
                    "symbol":"BTC",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "0.12",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"2,253,366",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"20,794,000",
                    "changeTotalFa":"90,017,302",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"2",
                    "name":"Ethereum",
                    "nameFa":"اتریوم",
                    "symbol":"ETH",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "2.7",
                    "p":"$3,495",
                    "pt":"435,000,001",
                    "changep":"253,366",
                    "change":"-2.16٪",
                    "changeLevel":"low",
                    "changeTotal":"2,420,000",
                    "changeTotalFa":"12,302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"3",
                    "name":"Tether",
                    "nameFa":"تتر",
                    "symbol":"USDT",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "415",
                    "p":"$1",
                    "pt":"68,501",
                    "changep":"1,366",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"94,372",
                    "changeTotalFa":"7,302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                },
                {
                    "id":"4",
                    "name":"Arbitrum",
                    "nameFa":"آربیتروم",
                    "symbol":"ARB",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "deposit": "170",
                    "p":"$1",
                    "pt":"0.11",
                    "changep":"0,06",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"4,372",
                    "changeTotalFa":"302,475",
                    "actions":{
                        "withdraw":true,
                        "deposit":true,
                        "trade":true,
                    }
                }
                
                
            ],
            topItems:[
                {
                    "id":"1",
                    "tag":"بیشترین‌سود",
                    "name":"Bitcoin",
                    "nameFa":"بیت‌کوین",
                    "symbol":"BTC",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$68,495",
                    "pt":"4,435,000,001",
                    "changep":"504.3",
                    "change":"+3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$2,794,372",
                    "changeTotalFa":"190,017,302,475",
                },
                {
                    "id":"2",
                    "tag":"بیشترین‌ضرر",
                    "name":"Ethereum",
                    "nameFa":"اتریوم",
                    "symbol":"ETH",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$3,495",
                    "pt":"435,000,001",
                    "changep":"142.54",
                    "change":"-2.16٪",
                    "changeLevel":"low",
                    "changeTotal":"$794,372",
                    "changeTotalFa":"19,017,302,475",
                },
                {
                    "id":"3",
                    "tag":"جدیدترین",
                    "name":"Tether",
                    "nameFa":"تتر",
                    "symbol":"USDT",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$1",
                    "pt":"68,501",
                    "changep":"0.0012",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$794,372",
                    "changeTotalFa":"19,017,302,475",
                },
                {
                    "id":"4",
                    "tag":"داغ‌ترین",
                    "name":"Arbitrum",
                    "nameFa":"آربیتروم",
                    "symbol":"ARB",
                    "pType":"TMN",
                    "pTypeFa":"تومان",
                    "p":"$1",
                    "pt":"0.11",
                    "changep":"0.021",
                    "change":"-3.16٪",
                    "changeLevel":"high",
                    "changeTotal":"$94,372",
                    "changeTotalFa":"19,017,302,475",
                }
                
            ],
            bankCards:[
                {
                    'id':1,
                    'name': "سامان",
                    'logo': "https://downloadly.ir/wp-content/uploads/2019/06/%D8%A8%D8%A7%D9%86%DA%A9-%D8%B3%D8%A7%D9%85%D8%A7%D9%86.png",
                    'CardNumber': "6219 8610 2565 1323",
                },
                {
                    'id':2,
                    'name': "ملی",
                    'logo': "https://www.rade.ir/wp-content/uploads/2019/12/Melli-logo.fw_.png",
                    'CardNumber': "6037 9911 8653 1770",
                }
            ],
            coins:[
                {
                    "id":"1",
                    "nameFa":"بیت کوین",
                    "symbol":"BTC",
                    "networks":["BTC"]
                },
                {
                    "id":"1",
                    "nameFa":"اتریوم",
                    "symbol":"ETH",
                    "networks":["ERC20","Arbitrum"]
                },
            ]
        }
    },
    mounted() {
    },
    computed: {
        filteredItems() {
            return this.List.filter(item => {
                return item.nameFa.toLowerCase().includes(this.search.toLowerCase()) || 
                item.name.toLowerCase().includes(this.search.toLowerCase()) || 
                item.pType.toLowerCase().includes(this.search.toLowerCase()) || 
                item.symbol.toLowerCase().includes(this.search.toLowerCase()) 
            })
        },
        filteredCoins() {
            return this.coins.filter(item => {
                return item.nameFa.toLowerCase().includes(this.searchCoins.toLowerCase()) || 
                item.symbol.toLowerCase().includes(this.searchCoins.toLowerCase()) 
            })
        }
    },
    methods: {
    }
}

</script>
    